import './index.scss';
import React from 'react';
import Button, {ButtonProps} from '../../atoms/button';

export type CardToolProps = {
    className?: string,
    title: string;
    text?: string;
    button?: ButtonProps;
    items?: {
        title: string,
        subtitle: string,
        price: string
    }[];
    onClick?: (e: MouseEvent) => void;
}
const CardTool = (props: CardToolProps) => {
    const onClick = (e: MouseEvent) => {
        if (props.onClick) {
            props.onClick(e);
        }
    }

    return (
        <div className={['card-tool', props?.className].join(' ')}>
            <h3 className="card-tool__title heading-h3">{props.title}</h3>
            <div className='card-tool__wrapper'>
                { props.items && props.items.map((item, i) => (
                    <div className='card-tool__item' key={`card-tool-item-${i}`}>
                        <div className='card-tool__item-wrapper'>
                            <span>{item.title}</span>
                            <span className='text-primary card-tool__item-subtitle'>{item.subtitle}</span>
                        </div>
                        <span className='card-tool__item-price'>{item.price}</span>
                    </div>
                ))}
                { props.text &&
                    <>
                        <span className='card-tool__text text-primary'>{props.text}</span>
                        {props.button && <Button className='button--underline card-tool__link' {...props.button} onClick={(e) => onClick(e)}/>}
                    </>
                }
            </div>
        </div>
    );
}

export default CardTool;
