import React from "react";
import './index.scss';

export type TagProps = {
    className?: string;
    text: string;
    href?: string;
    isRed?: boolean
}

const Tag = (props: TagProps) => {
    const CustomTag = props.href ? 'a' : 'div';
    const attrs = props.href ? {
        href: props.href
    } : {}

    return (
        <CustomTag className={['tag text-secondary', props?.className].join(' ')} {...attrs}>{props.text}</CustomTag>
    );
}

export default Tag;
