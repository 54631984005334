import './index.scss';
import { FormEvent, useState, useRef } from 'react';
import Parser from 'html-react-parser';
import ReCAPTCHA from 'react-google-recaptcha';
import Input from '../../atoms/input';
import Button, {ButtonProps} from '../../atoms/button';
import ListRadio from '../../molecules/list-radio';
import Util from '../../../assets/scripts/Util';

import ERROR_REQUEST from '../../../data/error.json';

type FormProps = {
    className?: string;
    agreement?: string;
    action: string;
    method: string;
    button: ButtonProps;
    items: any[]; // (InputProps|ListRadioProps)[]
    onSubmit?: (data: any) => void
}
const Form = (props: FormProps) => {
    const reCAPTCHARef = useRef<ReCAPTCHA>(null);

    const [formData, setFormData] = useState<Record<string, any>>({});
    const [errors, setErrors] = useState<any>({});
    const onChange = (elem: HTMLInputElement) => {
        setFormData((formData) => ({
            ...formData,
            [elem.name]: elem.value
        }));
    }
    const checkValid = () => {
        const errorsObj:any = {};
        props.items.forEach((item: any) => {
            if (item.required && (!formData[item.name] || formData[item.name] === '')) {
                errorsObj[item.name] = true
            }
        });
        return errorsObj;
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const form: HTMLFormElement = e.target as HTMLFormElement;
        const errorsObj = checkValid();

        if (!Object.keys(errorsObj).length) {
            const token = await reCAPTCHARef.current!.executeAsync();

            const newFormData = {
                ...formData,
                'g-recaptcha-response': token
            };

            setFormData(newFormData);

            Util.send(form.action, 'POST', newFormData).then(response => {
                const obj = JSON.parse(response);
                form.reset();
                if (props.onSubmit) {
                    props.onSubmit(obj)
                }
            }).catch(() => {
                if (props.onSubmit) {
                    props.onSubmit(ERROR_REQUEST);
                }
            });
        } else {
            setErrors(errorsObj);
        }
    }

    return (
        <form className={['form', props?.className].join(' ')} action={props.action} method={props.method} onSubmit={(e) => onSubmit(e)}>
            <ReCAPTCHA ref={reCAPTCHARef} size='invisible' sitekey='6LdgslgfAAAAAPhd4ioiuawHefMluXfOFwwa9Vy5'/>
            <div className="form__fields">
                {props.items.map((item, i) => (
                    item.type === 'price' ?
                        <ListRadio className='form__field form__field--radio' key={`form-field-${i}`} {...item} onChange={(e) => onChange(e)} error={errors[item.name] ? true : false }/> :
                        <Input className='form__field' key={`form-field-${i}`} {...item} onChange={(e) => onChange(e.target as HTMLInputElement)} error={errors[item.name] ? true : false }/>
                ))}
            </div>
            <div className="form__footer">
                { props.agreement &&
                    <div className="form__agreement">{Parser(props.agreement)}</div>
                }
                <Button className="form__button" {...props.button}/>
            </div>
        </form>
    );
}

export default Form;
