import './index.scss';
import React from 'react';
import Button, {ButtonProps} from '../../atoms/button';

export type BannerProps = {
    className?: string,
    title: string;
    button: ButtonProps,
    onClick?: (e: MouseEvent) => void
}
const Banner = (props: BannerProps) => {
    const onClick = (e: MouseEvent) => {
        if (props.onClick) {
            props.onClick(e);
        }
    }

    return (
        <div className={['banner', props?.className].join(' ')}>
            <div className="banner__wrapper">
                <h3 className="banner__title heading-2">{props.title}</h3>
                <Button className='banner__button' {...props.button} onClick={(e) => onClick(e)}/>
            </div>
        </div>
    );
}

export default Banner;
