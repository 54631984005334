import './style.scss';
import Button, {ButtonProps} from '../../atoms/button';

export type HeaderProps = {
    href: string;
    logo: string;
    alt: string;
    button: ButtonProps;
    onClick: (e: MouseEvent) => void;
}

const Header = (props: HeaderProps) => {
    return (
        <header className={'header container'}>
            <a href={props.href} className={'header__logo'}>
                <img src={props.logo} alt={props.alt}  className={'header__logo-img'}/>
            </a>
            <Button {...props.button} className={'button--underline header__button'} onClick={(e) => props.onClick(e)}/>
        </header>
    );
};

export default Header;
