import React from "react";
import './index.scss';

export type TechnologyProps = {
    className?: string;
    text?: string;
    href?: string;
    src: string;
    alt: string;
}

const Technology = (props: TechnologyProps) => {
    const CustomTag = props.href ? 'a' : 'div';
    const attrs = props.href ? {
        href: props.href
    } : {}

    return (
        <CustomTag className={['technology', props?.className].join(' ')} {...attrs}>
            <img className='technology__icon' src={props.src} alt={props.alt}/>
            { props.text && <span className='technology__text'>{props.text}</span>}
        </CustomTag>
    );
}

export default Technology;
