import './index.scss';
import React from 'react';
import Parser from 'html-react-parser';
import CardTool, {CardToolProps} from '../../molecules/card-tool';

type ToolsProps = {
    className?: string;
    title: string;
    text: string;
    items: CardToolProps[];
    onClick: (e: MouseEvent) => void;
}
const Tools = (props: ToolsProps) => {
    return (
        <div className={['tools', props?.className].join(' ')}>
            <h2 className="tools__title heading-2">{Parser(props.title)}</h2>
            <span className="tools__text text-primary">{Parser(props.text)}</span>
            <div className='tools__list'>
                {props.items.map((card, i) => (
                    !card.button ?
                    <CardTool className='tools__item' key={`card-tools-${i}`}{...card}/> :
                    <CardTool className='tools__item' key={`card-tools-${i}`}{...card} onClick={(e) => props.onClick(e)}/>
                ))}
            </div>
        </div>
    );
}

export default Tools;
