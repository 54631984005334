import React from "react";
import './index.scss';

export type RadioProps = {
    className?: string;
    text: string;
    name: string;
    value: string;
    id: string;
    checked?: boolean;
    onChange?: (elem: HTMLElement) => void;
}

const Radio = (props: RadioProps) => {
    const onChange = (e: React.ChangeEvent|React.MouseEvent, isSibling?: boolean) => {
        if (props.onChange) {
            props.onChange(isSibling ? (e.currentTarget as HTMLElement).previousElementSibling as HTMLElement : (e.target as HTMLElement));
        }
    }

    return (
        <div className='radio'>
            <input type='radio' name={props.name} value={props.value} defaultChecked={props.checked} onChange={(e: any) => onChange(e)}/>
            <span className='radio__label' onClick={(e) => onChange(e, true)}>{props.text}</span>
        </div>
    );
}

export default Radio;
