import React from "react";
import './index.scss';

export type ButtonProps = {
    className?: string;
    text: string;
    href?: string;
    onClick?: (e: MouseEvent) => void;
}

const Button = (props: ButtonProps) => {
    const onClick = (e: MouseEvent) => {
        if (props.onClick) {
            props.onClick(e);
        }
    }
    return (
        <button className={['button text-secondary', props?.className].join(' ')} onClick={(e: any) => onClick(e)} aria-label={`button with solid background and text`}>{props.text}</button>
    );
}

export default Button;
