import './index.scss';
import React, { useRef, useEffect } from 'react';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

type PopupProps = {
    className?: string;
    children?:any;
    onClose?: () => void
}
const Popup = (props: PopupProps) => {
    const popup = useRef(null);
    
    useEffect(() => {
        if (!popup.current) return;
        disableBodyScroll(popup.current);
    }, [popup]);

    const onClose = (e: any) => {
        if (props.onClose && e.target.dataset.close) {
            e.preventDefault();
            e.stopPropagation();
            props.onClose();
            clearAllBodyScrollLocks();
        };
    }
    return (
        <div className={['popup', props?.className].join(' ')} onClick={(e) => onClose(e)} data-close>
            <div className={"popup__overlay"}>
                <div className={"popup__wrapper"} ref={popup}>
                    <button className={"popup__button"} onClick={(e) => onClose(e)} data-close></button>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Popup;
