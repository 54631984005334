import React, { useEffect, useState } from "react";
import Icon from '../icon';
import Parser from 'html-react-parser';
import './index.scss';

export type InputProps = {
    className?: string;
    error: boolean | undefined;
    name: string;
    id: string;
    error_text?: string;
    type?: string;
    label?: string;
    placeholder?: string;
    onChange?: (e: InputEvent) => void;
}

const Input = (props: InputProps) => {
    const [goUp, setUp] = useState(false);
    const [isCorrent, setCorrent] = useState<undefined|boolean>(undefined);

    const onChange = (e: InputEvent) => {
        if (props.onChange) {
            props.onChange(e);
        }

        // @ts-ignore
        setCorrent(e.target.value !== '' && e.target.validity.valid);

        if (!(e.target as HTMLInputElement).value) {
            setUp(false);
        } else {
            setUp(true);
        }
    }

    useEffect(() => {
        if (props.error) {
            setCorrent(false);
        }
    }, [props.error])

    return (
        <div className={['input', goUp ? 'input--up' : ''].join(' ')} data-error={isCorrent !== undefined && !isCorrent}>
			{props.label && <div className='input__title'>{props.label}</div>}
            <input
                id={props.id}
                className='input__field'
                type={props.type || 'text'}
                name={props.name}
                onChange={(e: any) => onChange(e)}
            />
            <label className='input__label' htmlFor={props.id}>
                <span className='input__placeholder'>{props.placeholder}</span>
                {isCorrent !== undefined && <Icon className='input__icon' name={isCorrent ? 'success' : 'error'}/>}
            </label>
			{ isCorrent !== undefined && !isCorrent && props.error_text && <div className='input__error-text'>{Parser(props.error_text)}</div> }
		</div>
    );
}

export default Input;
