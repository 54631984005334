import './index.scss';
import React from 'react';
import Parser from 'html-react-parser';

type IntroductionProps = {
    className?: string;
    title: string;
    subtitle: string;
}
const Introduction = (props: IntroductionProps) => {
    return (
        <div className={['introduction', props?.className].join(' ')}>
            <h1 className="introduction__title heading-h1">{Parser(props.title)}</h1>
            <h2 className="introduction__subtitle heading-h3">{Parser(props.subtitle)}</h2>
        </div>
    );
}

export default Introduction;
