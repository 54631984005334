import './index.scss';
import React from 'react';
import Parser from 'html-react-parser';
import Technology, {TechnologyProps} from '../../atoms/technology';

export type ListTechnologyProps = {
    className?: string,
    title?: string;
    items: TechnologyProps[];
}
const ListTechnology = (props: ListTechnologyProps) => {
    return (
        <div className={['list-technology', props?.className].join(' ')}>
            { props.title && <h2 className="list-technology__title heading-2">{Parser(props.title)}</h2> }
            <div className='list-technology__items'>
                {props.items.map((technology, i) => (
                    <Technology key={`list-technology-${i}`}{...technology}/>
                ))}
            </div>
        </div>
    );
}

export default ListTechnology;
