import './style.scss';
import React, { useState, Suspense } from 'react';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import Sprite from '../../assets/scripts/Sprite';
import layout from '../../data/layout.json';
import data from '../../data/index.json';
import Link, {LinkProps} from '../../components/atoms/link';
import ListTechnology from '../../components/molecules/list-technology';
import Banner from '../../components/molecules/banner';
import Form from '../../components/molecules/form';
import Introduction from '../../components/organisms/introduction';
import Services from '../../components/organisms/services';
import Process from '../../components/organisms/process';
import Tools from '../../components/organisms/tools';
import Team from '../../components/organisms/team';
import Popup from '../../components/organisms/popup';

import Header from '../../components/organisms/header';

const Projects = React.lazy(() => import('../../components/organisms/projects'));

const Index = () => {
    const [popupOffer, setPopupOffer] = useState(false);
    const [popupProduct, setPopupProduct] = useState(false);
    const [popupSuccess, setPopupSuccess] = useState(false);
    const [popupDataSuccess, setPopupDataSuccess] = useState<{
        title: string,
        subtitle: string,
        link?: LinkProps
    }>({
        title: '',
        subtitle: ''
    });

    const showPopup = (e: MouseEvent, type: string) => {
        if (type === 'offer') {
            setPopupOffer(true)
        } else {
            setPopupProduct(true)
        }
    }

    return (
        <>
            <Header {...layout.header} onClick={(e) => showPopup(e, 'offer')}/>
            <div className='page'>
                <Introduction className='container page__introduction' {...data.introduction}/>
                    <Services className='container' {...data.services}/>
                    <Suspense fallback={<div></div>}>
                        <Projects className='container' {...data.projects}/>
                    </Suspense>
                    <Process className='container' {...data.process}/>
                    <ListTechnology className='container' {...data.technology}/>
                    <Tools className='container' {...data.tools} onClick={(e) => showPopup(e, 'product')}/>
                    <Team className='container' {...data.team}/>
                    <Banner className='container' {...data.banner} onClick={(e) => showPopup(e, 'offer')}/>
            </div>
            {popupOffer && <Popup className='popup--flex' onClose={() => setPopupOffer(false)}>
                <div className='popup__heading'>
                    <h2 className='popup__title heading-h2'>{data.popup.title}</h2>
                    <span className='text-primary'>{data.popup.subtitle}</span>
                </div>
                <Form {...data.popup.form} onSubmit={(data) => {
                    setPopupOffer(false);
                    setPopupSuccess(true);
                    setPopupDataSuccess(data);
                }}/>
            </Popup> }
            { popupProduct && <Popup className='small' onClose={() => setPopupProduct(false)}>
                <div className='popup__heading'>
                    <h2 className='popup__title heading-h2'>{data.popup_2.title}</h2>
                    <span className='text-primary'>{data.popup_2.subtitle}</span>
                </div>
                <Form {...data.popup_2.form} onSubmit={(data) => {
                    setPopupProduct(false);
                    setPopupSuccess(true);
                    setPopupDataSuccess(data);
                }}/>
            </Popup> }
            { popupSuccess && <Popup className='small' onClose={() => setPopupSuccess(false)}>
                <div className='popup__heading popup__heading--center'>
                    <h2 className='popup__title heading-h2'>{popupDataSuccess.title}</h2>
                    <span className='text-primary'>{popupDataSuccess.subtitle}</span>
                    {popupDataSuccess.link && <Link className='popup__link link--base' {...popupDataSuccess.link} onClick={() => {
                        setPopupSuccess(false);
                        clearAllBodyScrollLocks();
                    }}/> }
                </div>
            </Popup> }
            <Sprite/>
        </>
    );
}

export default Index;
