import React from "react";
import Icon, {IconProps} from '../../atoms/icon';
import './index.scss';

export type LinkProps = {
    className?: string;
    text: string;
    href?: string;
    target?: string;
    icon?: IconProps;
    shape?: 'square';
    onClick?: (e: MouseEvent) => void;
}

const Link = (props: LinkProps) => {
    const onClick = (e: MouseEvent) => {
        if (props.onClick) {
            props.onClick(e);
        }
    }
    return (
        <a href={props.href} target={props.target || '_self'} className={['link text-secondary', props.shape? `link--${props.shape}` : '', props?.className, props.icon ? 'link--icon' : ''].join(' ')} onClick={(e: any) => onClick(e)}>
            {props.icon && <Icon className='link__icon' {...props.icon}/>}
            <span className='link__text'>{props.text}</span>
        </a>
    );
}

export default Link;
