import React from 'react';
import SvgSprite from 'react-svg-sprite'

const Sprite = () => {
	return (
		<SvgSprite
			style={{
				position: "absolute",
				top: "-99999px",
				left: "-99999px",
				zIndex: "19999999",
				display: "block"
			}}
			symbols={[
                {
                    name: "link",
                    svg: `
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_231_1442)">
                    <path d="M10.1794 0.976074H15.9639M15.9639 0.976074V6.60174M15.9639 0.976074L9.95312 7.47426" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" fill="none"/>
                    <path d="M5.79874 1.95886C6.35102 1.95886 6.79874 1.51115 6.79874 0.958862C6.79874 0.406578 6.35102 -0.0411377 5.79874 -0.0411377V1.95886ZM16.945 10.8759C16.945 10.3236 16.4973 9.87593 15.945 9.87593C15.3927 9.87593 14.945 10.3236 14.945 10.8759H16.945ZM5.79874 -0.0411377H1.41807V1.95886H5.79874V-0.0411377ZM0.0180664 1.35886V15.6719H2.01807V1.35886H0.0180664ZM1.31807 16.9719H15.745V14.9719H1.31807V16.9719ZM16.945 15.7719V10.8759H14.945V15.7719H16.945ZM15.745 16.9719C16.4077 16.9719 16.945 16.4347 16.945 15.7719H14.945C14.945 15.3301 15.3032 14.9719 15.745 14.9719V16.9719ZM0.0180664 15.6719C0.0180664 16.3899 0.600092 16.9719 1.31807 16.9719V14.9719C1.70467 14.9719 2.01807 15.2853 2.01807 15.6719H0.0180664ZM1.41807 -0.0411377C0.644869 -0.0411377 0.0180664 0.585662 0.0180664 1.35886H2.01807C2.01807 1.69023 1.74944 1.95886 1.41807 1.95886V-0.0411377Z" stroke="none"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_231_1442">
                    <rect width="17" height="17" stroke="none"/>
                    </clipPath>
                    </defs>
                    </svg>
                    `
                },
                {
                    name: "case",
                    svg: `
                    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_231_1441)">
                    <path d="M10.1794 0.976074H15.9639M15.9639 0.976074V6.60174M15.9639 0.976074L12.2229 4.73193M1 15.9995L4.74098 12.2437" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M5.79874 0.958862H1.41807C1.19715 0.958862 1.01807 1.13795 1.01807 1.35886V6.99988M15.945 10.8759V15.7719C15.945 15.8824 15.8555 15.9719 15.745 15.9719H9.99951M5.79874 15.9719H1.31807C1.15238 15.9719 1.01807 15.8376 1.01807 15.6719V10.8759" stroke="white" stroke-width="2" stroke-linecap="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_231_1441">
                    <rect width="17" height="17" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    `
                },
                {
                    name: "success",
                    svg: `<svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line y1="-1" x2="7.72402" y2="-1" transform="matrix(0.715007 0.699118 -0.715007 0.699118 0 5.59998)" stroke="#070707" stroke-width="2" stroke-linejoin="round"/>
                    <line y1="-1" x2="12.8734" y2="-1" transform="matrix(0.715007 -0.699118 0.715007 0.699118 5.52271 11)" stroke="#070707" stroke-width="2" stroke-linejoin="round"/>
                    </svg>
                    `
                },
                {
                    name: "error",
                    svg: `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.20857L10.7914 0L6 4.79143L1.20857 0L0 1.20857L4.79143 6L0 10.7914L1.20857 12L6 7.20857L10.7914 12L12 10.7914L7.20857 6L12 1.20857Z" fill="#E50058"/>
                    </svg>
                    `
                }
			]}
		/>
	)
};

export default Sprite;
