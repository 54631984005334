import './index.scss';
import React from 'react';
import Parser from 'html-react-parser';
import CardProcess, {CardProcessProps} from '../../molecules/card-process';

type ProcessProps = {
    className?: string;
    title: string;
    text: string;
    items: CardProcessProps[]
}
const Process = (props: ProcessProps) => {
    return (
        <div className={['process', props?.className].join(' ')}>
            <h2 className="process__title heading-2">{Parser(props.title)}</h2>
            <span className="process__text text-primary">{Parser(props.text)}</span>
            <div className='process__list'>
                {props.items.map((card, i) => (
                    <CardProcess className='process__item' key={`card-process-${i}`}{...card} number={i + 1}/>
                ))}
            </div>
        </div>
    );
}

export default Process;
