import './index.scss';
import React from 'react';
import Parser from 'html-react-parser';
import CardService, {CardServiceProps} from '../../molecules/card-service';

type ServicesProps = {
    className?: string;
    title: string;
    items: CardServiceProps[]
}
const Services = (props: ServicesProps) => {
    return (
        <div className={['services', props?.className].join(' ')}>
            <h2 className="services__title heading-h2">{Parser(props.title)}</h2>
            <div className='services__list'>
                {props.items.map((card, i) => (
                    <CardService className='services__item' key={`services-${i}`} {...card}/>
                ))}
            </div>
        </div>
    );
}

export default Services;
