import './index.scss';
import React from 'react';
import Parser from 'html-react-parser';
import {ButtonProps} from '../../atoms/button';
import {LinkProps} from '../../atoms/link';

type TeamProps = {
    className?: string;
    title: string;
    items: (ButtonProps|LinkProps)[];
}
const Team = (props: TeamProps) => {
    return (
        <div className={['team', props?.className].join(' ')}>
            <h2 className="team__title heading-h2">{Parser(props.title)}</h2>
            {/* <div className="team__list">
                {props.items.map((item, i) => (
                    i !== 0 ?
                        <Link className='link--base' {...item} key={`team-${i}`}/> :
                        <Link className='team__list-link' {...item} shape='square' key={`team-${i}`}/>
                ))}
            </div> */}
        </div>
    );
}

export default Team;
