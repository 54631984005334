import './index.scss';
import React from 'react';
import Parser from 'html-react-parser';

export type CardProcessProps = {
    className?: string,
    title: string;
    text: string;
    href?: string;
    number?: number;
}
const CardProcess = (props: CardProcessProps) => {
    const CustomTag = props.href ? 'a' : 'span';
    const attrs = props.href ? {
        href: props.href
    } : {}

    return (
        <div className={['card-process', props?.className].join(' ')}>
            <CustomTag className="card-process__title heading-h3" {...attrs}>{Parser(props.title)}</CustomTag>
            <span className="card-process__text text-primary">{Parser(props.text)}</span>
            { props.number &&
                <span className='card-process__number'>{props.number}</span>
            }
        </div>
    );
}

export default CardProcess;
