import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Index from './pages/index';
import './App.scss';

const RoutesWrp = () => {
    return (
      <>
        <Routes>
          <Route path={'/'} element={<Index/>}/>
        </Routes>
      </>
    )
  }

function App() {
  return (
    <Router>
        <RoutesWrp />
    </Router>
  );
}

export default App;
