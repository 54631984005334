import './index.scss';
import React, {useEffect, useState} from 'react';
import Parser from 'html-react-parser';
import Radio, {RadioProps} from '../../atoms/radio';

export type ListRadioProps = {
    className?: string,
    label?: string;
    items: RadioProps[];
    error: boolean;
    error_text?: string;
    onChange?: (e: HTMLInputElement) => void;
}
const ListRadio = (props: ListRadioProps) => {
    const [checked, isChecked] = useState('');
    const [isCorrent, setCorrent] = useState<boolean>(true);

    const onChange = (elem: HTMLInputElement) => {
        isChecked(elem.value);
        if (props.onChange) {
            props.onChange(elem);
        }
        setCorrent(true);
    }

    useEffect(() => {
        if (props.error) {
            setCorrent(false);
        }
    }, [props.error])
    return (
        <div className={['list-radio', props?.className].join(' ')} data-error={!isCorrent}>
            { props.label && <h2 className="list-radio__title heading-h4">{Parser(props.label)}</h2> }
            <div className='list-radio__items'>
                {props.items.map((radio, i) => (
                    <Radio key={`list-radio-${i}`} id={radio.id} name={radio.name} value={radio.value} text={radio.text} checked={checked === radio.value} onChange={(e: any) => onChange(e)}/>
                ))}
            </div>
            { !isCorrent && props.error_text && <div className='input__error-text'>{Parser(props.error_text)}</div> }
        </div>
    );
}

export default ListRadio;
