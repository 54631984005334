import './index.scss';
import React from 'react';
import Parser from 'html-react-parser';
import Tag, {TagProps} from '../../atoms/tag';

export type CardServiceProps = {
    className?: string,
    title: string;
    text: string;
    href?: string;
    items: TagProps[]
}
const CardService = (props: CardServiceProps) => {
    const CustomTag = props.href ? 'a' : 'span';
    const attrs = props.href ? {
        href: props.href
    } : {}

    return (
        <div className={['card-service', props?.className].join(' ')}>
            <CustomTag className="card-service__title heading-h3" {...attrs}>{Parser(props.title)}</CustomTag>
            <span className="card-service__text text-primary">{Parser(props.text)}</span>
            <div className='card-service__list'>
                {props.items.map((tag, i) => (
                    <Tag className='card-service__item' key={`card-service-${i}`} {...tag} />
                ))}
            </div>
        </div>
    );
}

export default CardService;
